jQuery(function(){
	var $ = jQuery;
    //=====================================================
	//  Run Polyfills
	//=====================================================
	// Pointer Events
    if (!Modernizr.pointerevents) {
    	window.pointerEventsPolyfill();
    }

    // Object Fit Images
    if (!Modernizr.objectfit) {
    	objectFitImages();
    }

    // CSS Multi Column
    if (!Modernizr.csscolumns) {
		$('.col-layout').multicolumn();
	}


	//=====================================================
	//  Plugins
	//=====================================================
	// Match Heights
	$('.match-height').matchHeight({byRow: true});




	//=====================================================
	//  Smooth Scroll to Anchors
	//=====================================================
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				if($('.hamburger').hasClass('open')) {
					$('.hamburger').click();
				}
				$('html, body').animate({
					scrollTop: target.offset().top + $(window).scrollTop() - 80
				}, 1000);
				console.log(target.offset().top);
				return false;
			}
		}
	});


	//=====================================================
	//  Menu/Hamburger
	//=====================================================
	$(".hamburger").on("click", function(e) {
		$(this).toggleClass('open');
	});


	//=====================================================
	//  Scroll Pin Content
	//=====================================================
	var $scrollPinWrapper = $('.scroll-pin-wrapper');


	if ( $scrollPinWrapper.length > 0 ) {

		var $scrollPinContent = $('.scroll-pin-content'),
			origTopPos = $scrollPinWrapper.offset().top,
			$win = $(window),
			lastScrollTop = $win.scrollTop();

		function pinScroll() {

			if ( origTopPos < $win.scrollTop() ) {
				// scrolled past the top.

				if ( !$scrollPinContent.hasClass('pinned') ) {
					// hasn't been pinned yet.
					$scrollPinContent.addClass('pinned');
				}

				// check if we've hit the bottom.
				if ( $scrollPinContent.height() + $win.scrollTop() > $scrollPinWrapper.height() + $scrollPinWrapper.offset().top ) {
					// hit the bottom. pin it to the bottom.
					$scrollPinContent.addClass('pinned-end');
				}
				else if ( $scrollPinContent.hasClass('pinned') ) {
					// catch it on the way back up
					$scrollPinContent.removeClass('pinned-end');
				}
			}
			else if ( $scrollPinContent.hasClass('pinned') ) {
				// We gone back up, remove the pin
				$scrollPinContent.removeClass('pinned');

			}
		}

		// run it once at init
		pinScroll();

		// And run it if the window's resized
		$win.resize(function(){
			pinScroll();
		});

		var raf = window.requestAnimationFrame ||
				  window.webkitRequestAnimationFrame ||
				  window.mozRequestAnimationFrame ||
				  window.msRequestAnimationFrame ||
				  window.oRequestAnimationFrame;

		if (raf) {
			scrollLoop();
		}

		function scrollLoop() {
			var scrollTop = $win.scrollTop();
			if (lastScrollTop === scrollTop) {
				raf(scrollLoop);
				return;
			}
			else {
				lastScrollTop = scrollTop;
				pinScroll();
				raf(scrollLoop);
			}
		}
	}




	//=====================================================
	//  Modal
	//=====================================================
	$('.launch-modal').click(function(){
		var modal = $(this).data('modal');

		if ($(modal).find('.video-wrapper')[0]) {
			// add iframe if video
			var $videoWrapper = $(modal).find('.video-wrapper');
			var videoData = parseVideoURL($videoWrapper.data('video-url'));
			var videoHtml = "";

			if (videoData.provider === 'youtube') {
				videoHtml = '<iframe type="text/html" src="https://www.youtube.com/embed/'+videoData.id+'?autoplay=1" frameborder="0"></iframe>';
			}
			else if (videoData.provider === 'vimeo') {
				videoHtml = '<iframe src="https://player.vimeo.com/video/'+videoData.id+'?autoplay=1" width="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
			}
			$videoWrapper.html(videoHtml);
		}

		// Fade in modal
		$('.modal-mask').hide();
		$(modal).fadeIn();
	});

	$('.modal .close-button, .modal-mask').click(function(){
		$(this).closest('.modal-mask').fadeOut(300);

		// Remove the iframes
		$(this).find('.video-wrapper').html('');
	});



	//=====================================================
	//  Map
	//=====================================================
	/*
	*  new_map
	*
	*  This function will render a Google Map onto the selected jQuery element
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/

	var new_map = function( $el ) {
		
		// var
		var $markers = $el.find('.marker');
		
		
		// vars
		var args = {
			zoom		: 16,
			center		: new google.maps.LatLng(0, 0),
			mapTypeId	: google.maps.MapTypeId.ROADMAP,
			scrollwheel	: false,
			mapTypeControl: false
		};
		
		
		// create map	        	
		var map = new google.maps.Map( $el[0], args);

		$(this).data('map', map);
		
		
		// add a markers reference
		map.markers = [];
		
		
		// add markers
		$markers.each(function(){
			
	    	add_marker( $(this), map );
			
		});
		
		
		// center map
		center_map( map );
		
		
		// return
		return map;
		
	}

	/*
	*  add_marker
	*
	*  This function will add a marker to the selected Google Map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	var add_marker = function( $marker, map ) {

		// var
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

		// create marker
		var marker = new google.maps.Marker({
			position	: latlng,
			map			: map
		});

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() )
		{
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content		: $marker.html()
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {

				infowindow.open( map, marker );

			});
		}
	}

	/*
	*  center_map
	*
	*  This function will center the map, showing all markers attached to this map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	var center_map = function( map ) {

		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each( map.markers, function( i, marker ){

			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );

		});

		// only 1 marker?
		if( map.markers.length === 1 )
		{
			// set center of map
		    map.setCenter( bounds.getCenter() );
		    map.setZoom( 16 );
		}
		else
		{
			// fit to bounds
			map.fitBounds( bounds );
		}

	}

	$('.acf-map').each(function(){
		
		if ( $(this).is(":visible") ) {
			// create map
			map = new_map( $(this) );
		}

	});

});